import React, { useState } from 'react';

const Input = ({ title, type, name, placeholder, icon, value, onChange, maxLength, error = "" }) => {
    const [emailValidation, setEmailValidation] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const handleBlur = () => {
        if (type === 'email' && value && !isValidEmail(value)) {
            setEmailValidation('Please enter a valid email address');
        } else {
            setEmailValidation('');
        }
    };

    const isValidEmail = (email) => {
        // Basic email format validation using a regular expression
        const regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    return (
        <div className="form-group position-relative mb-2">
            <label className="form-check-label logiform_lable">
                {title}
            </label>
            <input
                type={isPasswordVisible && type === 'password' ? 'text' : type}
                name={name}
                className="rental_login_fm"
                placeholder={placeholder}
                value={value}
                onBlur={handleBlur}
                onChange={onChange}
                maxLength={maxLength || (type === 'tel' ? "10" : undefined)}
                autoComplete='off'
            />
            <div className="input_icone">
                <img src={icon} alt="icon" />
            </div>
            {type === 'password' && (
                <div
                    className={`fa ${isPasswordVisible ? 'fa-eye-slash' : 'fa-eye'}`}
                    onClick={togglePasswordVisibility}
                    style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '35px' }}
                >

                </div>
            )}
            <div className="error_input">{error || emailValidation}</div>
        </div>
    );
};

export default Input;
