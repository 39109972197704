import React, { useEffect, useState } from 'react'
import Axios from '../../Axios'
import ProductCard from '../../Components/ProductCard';
import Check from '../../Components/Check';
import Radio from '../../Components/Radio';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useDispatch, useSelector } from 'react-redux';
import { updateFilters } from '../../Redux/filterSlice';
import { updateLoader } from '../../Redux/loaderSlice';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';

function valuetext(value) {
    // console.log("valuetext",value);
    return `${value}°C`;
}

const Productfiler = () => {
    const dispatch = useDispatch();
    const category = useSelector((state) => state.category);
    const filers = useSelector((state) => state.filers);
    const city = useSelector((state) => state.city);
    const [filter, setFilter] = useState([]);
    const [value, setValue] = React.useState([10, 10000]);
    const [collapse, setCollapse] = useState(false)
    const [pagination, setPagination] = useState();
    const [property, setProperty] = useState([]);
    const initialData = {
        perpage: 6,
        price_min: value[0],
        price_max: value[1]
    }

    const [formData, setFormData] = useState(initialData);
    const [formDataNew, setFormDataNew] = useState([]);
    const [page, setPage] = useState(1);


    const getFilters = async () => {
        try {
            dispatch(updateLoader({ loader: true }));
            const resp = await Axios.get('/filters')
            if (resp.data.code == "EC200") {
                setFilter(resp.data.data.data)
            }
            dispatch(updateLoader({ loader: false }));
        } catch (error) {
            dispatch(updateLoader({ loader: false }));
            if (error.response && error.response.status === 404) {
                console.log(error.response)
            } else {
                console.log(error.message);
            }
        }
    }
    const [reset, setReset] = useState(0)
    const handleReset = () => {
        setReset(1);
        setFormData(initialData);
        getProperty(initialData, 1, true);
        setTimeout(() => {
            setReset(0);
        }, 1000);
    };

    function getAllValue(data) {
        let extractedValues = {};
        for (const key in data) {
            if (key === 'check_ids' || key.startsWith('radio_ids_')) {
                if(data[key]){
                	extractedValues[key] = data[key];
                }
            }
        }
        return Object.values(extractedValues).join(',');
    }

    const getProperty = async (e, page, status) => {
        try {
            const params = e;
            params['page'] = page;
            params['filters_ids'] = getAllValue(e);
            // console.log(getAllValue(e))
            params['check_in'] = filers.check_in;
            params['check_out'] = filers.check_out;
            params['category'] = filers.category;
            params['country_ids'] = filers.country_ids;
            params['adults'] = filers.adults;
            params['children'] = filers.children;
            params['infants'] = filers.infants;
            params['rooms'] = filers.rooms;
            const resp = await Axios.get('/property', { params })

            if (resp.data.code == "EC200") {

                setPagination(resp.data.data.pagination)
                if (status) {
                    setProperty(resp.data.data.data)
                } else {
                    setProperty(prevState => [...prevState, ...resp.data.data.data]);
                }

            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                console.log('404 ', error.response.data.message)
                setProperty([])
            } else {
                console.log('error ', error.message);
            }

            setPagination(null)
        }
    }

    useEffect(() => {
        // setCollapse(true)
        setPage(1)
        getProperty(formData, 1, true);
    }, [formData, filers
    ])

    const handleChangePrice = (event, newValue) => {
        setValue(newValue);
        setFormData(prevState => ({ ...prevState, price_min: newValue[0], price_max: newValue[1] }));
    };

    const handleChangeCheck = (newValue) => {
        setFormData(prevState => {
            const currentFilters = prevState.check_ids ? prevState.check_ids.split(',') : [];
            const filtersSet = new Set(currentFilters);

            if (filtersSet.has(newValue)) {
                filtersSet.delete(newValue);
            } else {
                filtersSet.add(newValue);
            }

            return { ...prevState, check_ids: Array.from(filtersSet).join(',') };
        });
    };

    const handleChangeRadio = (ind, value) => {
        setFormData(prevState => {
            // Update the specific radio group state
            return { ...prevState, [`radio_ids_${ind}`]: value };
        });
    };


    const handleChangeApi = (name, e) => {
        setFormData(prevState => ({
            ...prevState,
            [name]: e,
        }));
        dispatch(updateFilters({ [name]: e }));
    };


    const handleChangeShortBy = (event) => {
        const selectedIndex = event.target.selectedIndex;
        setFormData(prevState => ({
            ...prevState,
            orderbytype: event.target.options[selectedIndex].getAttribute('data-1'),
            orderby: event.target.options[selectedIndex].getAttribute('data-2')
        }));
    }

    // useEffect(() => {
    //     setFormData(prevState => ({
    //         ...prevState,
    //         ...filers,
    //     }))
    //     console.log(filers)
    // }, [filers])

    const loadMore = () => {

        if (pagination.last_page > page) {
            getProperty(formData, pagination.current_page + 1, false);
            setPage(pagination.current_page + 1)
        }
    }

    useEffect(() => {
        getFilters();
    }, [])


    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 100 >= document.documentElement.offsetHeight) {
            if (pagination) {
                if (pagination.last_page !== page) {
                    // loadMore(); 
                }
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            <div className="rental_project_category d-none">
                <div className="container">
                    <div className="category_inner">

                    </div>
                </div>
            </div>


            {/*--end---list--category----*/}
            <div className="property_detaols_main_wrapper">

                <div className="container">
                    <div className='mobile_filter'>
                        <Dropdown>
                            <Dropdown.Toggle className="rental_filter_btn" variant="" id="dropdown-basic">
                                Filter <i className="fa fa-filter" aria-hidden="true"></i>

                            </Dropdown.Toggle>

                            <Dropdown.Menu className='filter_dropdown_width'>
                                <div className="filter_left_sidebar">
                                    <div className="panel panel-default">
                                        <div
                                            className="panel-headin filter_title_header"
                                            role="tab"
                                            id="headingOne">
                                            <h4 className="panel-title price_range_title"><Link >Price</Link></h4>
                                        </div>

                                        <Slider
                                            getAriaLabel={() => 'Minimum distance shift'}
                                            value={value}
                                            onChange={(_, newValue) => setValue(newValue)}
                                            onChangeCommitted={handleChangePrice}
                                            valueLabelDisplay="on"
                                            aria-label="Always visible"
                                            min={1}
                                            max={10000} 
                                            // step={100}
                                            getAriaValueText={valuetext}
                                            className='rangeslider_filter'
                                        />
                                        <div
                                            className="collapse show"
                                            id="collapseExample"
                                            role="tabpanel"
                                            aria-labelledby="headingOne"
                                        >
                                            <div className="panel-body d-none">
                                                <div className="price-input">
                                                    <div className="field">
                                                        <input
                                                            type="text" className="input-min_box form-control"
                                                            value={value[0]} readOnly={true} placeholder="Min"
                                                        />
                                                    </div>
                                                    <div className="separator_minmax">-</div>
                                                    <div className="field">
                                                        <input
                                                            type="text"
                                                            className="input-min_box form-control"
                                                            placeholder="Max"
                                                            readOnly={true}
                                                            value={value[1]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <Radio defaulselected={1} data={category} id={'propert_filter_' + 11} title={'Category'} reset={reset} getSelectedValue={(e) => handleChangeApi('category', e)} collapse={collapse} />
                                    </div>

                                    {
                                        filter.map((item, ind) => {
                                            if (item.type == 1) {
                                                return (
                                                    <Check
                                                        defaulselected={1}
                                                        data={item.filters}
                                                        id={'property_filter_' + ind}
                                                        title={item.name}
                                                        reset={reset}
                                                        getSelectedValue={(e) => handleChangeCheck(e)}
                                                        collapse={collapse}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <Radio
                                                        defaulselected={1}
                                                        data={item.filters}
                                                        id={'property_filter_' + ind}
                                                        title={item.name}
                                                        reset={reset}
                                                        getSelectedValue={(e) => handleChangeRadio("radio_ids_" + ind, e)}
                                                        collapse={collapse}
                                                    />
                                                );
                                            }
                                        })
                                    }

                                    <Radio defaulselected={1} data={city} id={'property_city'} title={'Property city'} reset={reset} getSelectedValue={(e) => handleChangeApi('city_ids', e)} collapse={collapse} />
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <span className='filter_text_dummy'>Filter</span>

                            <button className='filter_react_all' onClick={() => handleReset()}>Reset all</button>

                            <div className="filter_number d-none" onClick={() => setCollapse(!collapse)}>Filters<span className="minuplus">{collapse ? "-" : "+"}</span> </div>


                            <div className="filter_left_sidebar desktop_filter">
                                <div className="panel panel-default">
                                    <div
                                        className="panel-headin filter_title_header"
                                        role="tab"
                                        id="headingOne" style={{ marginBottom: '14%' }}
                                    >
                                        <h4 className="panel-title">
                                            <Link >Price</Link>
                                        </h4>
                                    </div>

                                    <Slider
                                        getAriaLabel={() => 'Minimum distance shift'}
                                        value={value}
                                        onChange={(_, newValue) => setValue(newValue)}
                                        onChangeCommitted={handleChangePrice}
                                        valueLabelDisplay="on"
                                        aria-label="Always visible"
                                        min={1}
                                        max={10000}
                                        // step={100}
                                        getAriaValueText={valuetext}
                                        className='rangeslider_filter'
                                    />
                                    <div
                                        className="collapse show"
                                        id="collapseExample"
                                        role="tabpanel"
                                        aria-labelledby="headingOne"
                                    >
                                        <div className="panel-body d-none">
                                            <div className="price-input">
                                                <div className="field">
                                                    <input
                                                        type="text" className="input-min_box form-control"
                                                        value={value[0]} readOnly={true} placeholder="Min"
                                                    />
                                                </div>
                                                <div className="separator_minmax">-</div>
                                                <div className="field">
                                                    <input
                                                        type="text"
                                                        className="input-min_box form-control"
                                                        placeholder="Max"
                                                        readOnly={true}
                                                        value={value[1]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Radio defaulselected={1} data={category} id={'propert_filter_' + 11} title={'Category'} reset={reset} getSelectedValue={(e) => handleChangeApi('category', e)} collapse={collapse} />
                                </div>

                                {
                                    filter.map((item, ind) => {
                                        if (item.type == 1) {
                                            return (
                                                <Check
                                                    defaulselected={1}
                                                    data={item.filters}
                                                    id={'property_filter_' + ind}
                                                    title={item.name}
                                                    reset={reset}
                                                    getSelectedValue={(e) => handleChangeCheck(e)}
                                                    collapse={collapse}
                                                />
                                            );
                                        } else {
                                            return (
                                                <Radio
                                                    defaulselected={1}
                                                    data={item.filters}
                                                    id={'property_filter_' + ind}
                                                    title={item.name}
                                                    reset={reset}
                                                    getSelectedValue={(e) => handleChangeRadio(ind, e)}
                                                    collapse={collapse}
                                                />
                                            );
                                        }
                                    })
                                }
                                {/* {
                                    city.map((item, ind) => {
                                        return (
                                            <Radio defaulselected={1} data={item} id={'property_city' + ind} title={'Property city'} reset={reset} getSelectedValue={(e) => handleChangeRadio('city_ids' + ind, e)} collapse={collapse} />
                                        )
                                    })
                                } */}

                                <Radio defaulselected={1} data={city} id={'property_city'} title={'Property city'} reset={reset} getSelectedValue={(e) => handleChangeApi('city_ids', e)} collapse={collapse} />

                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="container">
                                <div className="filter_wrapper">
                                    <div className="filter_inner_wrapper">
                                        <div className="filter_number">
                                            {pagination && pagination.total !== 0 ? (
                                                `${pagination.total} Rooms available`
                                            ) : (
                                                <div style={{ textAlign: 'center' }}>No rooms found</div>
                                            )}
                                        </div>

                                        <div className="filter_dropdown d-flex">
                                            <label style={{ whiteSpace: 'nowrap', marginRight: '6%' }} htmlFor="sortBy" className='shortbylable'>Sort by</label>
                                            <select
                                                className="form-select sort_select_input"
                                                aria-label="Default select example"
                                                onChange={handleChangeShortBy}
                                            >
                                                <option>Default</option>
                                                {/* <option value={1} data-1="rating" data-2="ASC">Rating  Low to Hight</option> */}
                                                <option value={1} data-1="rating" data-2="DESC">Popularity</option>
                                                <option value={2} data-1="price" data-2="DESC">Price:High to Low</option>
                                                <option value={2} data-1="price" data-2="ASC">Price:Low to High</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">


                                    {property.map((item, ind) => (
                                        <ProductCard key={ind} data={item} oneRow={4} />
                                    ))}

                                    {pagination && (pagination.last_page !== page) && (
                                        <>
                                            <div className='text-center mt-3'>
                                                <div className="booknow_btn">
                                                    <Link className="btn property_book_btn btn-lg" onClick={loadMore}>Load More</Link>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Productfiler