import React, { useEffect, useState } from 'react'
import { useParams, Link, useNavigate } from "react-router-dom";
import Axios from '../../Axios';
import ProductCard from '../../Components/ProductCard';
import Rating from '@mui/material/Rating';
import { Avatar } from '@mui/material';
import { DateRangePicker } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';
// import ImageList from '@mui/material/ImageList';
// import ImageListItem from '@mui/material/ImageListItem';
import Dropdown from 'react-bootstrap/Dropdown';
import { deepOrange, deepPurple } from '@mui/material/colors';
import { updateLoader } from '../../Redux/loaderSlice';
import { useDispatch, useSelector } from 'react-redux';
import Countinc from '../../Components/Countinc';
import { updateFilters } from '../../Redux/filterSlice';
import { getFormatDate, getTodayDate } from '../../utility';
import { updateCommons } from '../../Redux/commonSlice';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Carousel from 'react-bootstrap/Carousel';
// import GetStaticValue from '../../utility/GetStaticValue';
const Product = () => {

  const dispatch = useDispatch();
  let { id } = useParams();
  const [property, setProperty] = useState();
  const filers = useSelector((state) => state.filers);
  const { beforeToday } = DateRangePicker;
  const [rating, setRating] = useState([]);
  const [recommended, setRecommended] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const navigate = useNavigate()
  const common = useSelector((state) => state.common);
  const [resp, setResp] = useState(null);
  const [open, setOpen] = useState(false);
  const authenticatedid = useSelector((state) => state.user.id);
  const [capacity, setCapacity] = useState(10);
  const [error, setError] = useState('');
  const [errorMsg, setErrorMsg] = useState('')
  const [selectedDateRange, setSelectedDateRange] = useState([new Date(filers.check_in), new Date(filers.check_out)]);
  const [checkin, setCheckIn] = useState('')
  const [checkout, setCheckOut] = useState('')

  const getProperty = async (e) => {
    try {
      dispatch(updateLoader({ loader: true }));
      const resp = await Axios.get(`/property/${e}`)
      console.log(resp.data.data.data)
      if (resp.data.code == "EC200") {
        setProperty(resp.data.data.data)
        setCheckIn(resp.data.data.data.check_in_time)
        setCheckOut(resp.data.data.data.check_in_time)

      }
      dispatch(updateLoader({ loader: false }));
    } catch (error) {
      dispatch(updateLoader({ loader: false }));
      if (error.response && error.response.status === 404) {
        console.log('404 ', error.response.data.message)
      } else {
        console.log('error ', error.message);
      }
      setProperty(null)
    }
  }
  function useWindowWidth() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowWidth;
  }
  const windowWidth = useWindowWidth();
  const showOneCalendar = windowWidth <= 481;
  const reserveNow = (property) => {
    const token = localStorage.getItem('token'); // Check if token exists in local storage
    if (token) {
      const state = {
        id: property.id,
        name: property.name,
        price: property.price,
        rating: property.rating,
        image: property.images[0]?.image
      };
      navigate('/user/checkout', { state });
    } else {
      dispatch(updateCommons({ loginshow: true }));
    }
  };

  const getRating = async (e) => {
    try {
      dispatch(updateLoader({ loader: true }));
      const resp = await Axios.get(`/rating/${e}`)
      console.log(resp.data.data.data)
      if (resp.data.code == "EC200") {
        setRating(resp.data.data.data.data)
      }
      dispatch(updateLoader({ loader: false }));
    } catch (error) {
      dispatch(updateLoader({ loader: false }));
      if (error.response && error.response.status === 404) {
        console.log('404 ', error.response.data.message)
      } else {
        console.log('error ', error.message);
      }
      setRating([])
    }
  }

  const getRecommended = async (e) => {
    try {
      dispatch(updateLoader({ loader: true }));
      const resp = await Axios.get(`/recommended/${e}`)
      console.log(resp.data.data.data)
      if (resp.data.code == "EC200") {
        setRecommended(resp.data.data.data)
      }
      dispatch(updateLoader({ loader: false }));
    } catch (error) {
      dispatch(updateLoader({ loader: false }));
      if (error.response && error.response.status === 404) {
        console.log('404 ', error.response.data.message)
      } else {
        console.log('error ', error.message);
      }
      setRecommended([])
    }
  }

  const getAmenities = async (e) => {
    try {
      dispatch(updateLoader({ loader: true }));
      const resp = await Axios.get(`/amenities/${e}`)
      console.log(resp.data.data.data)
      if (resp.data.code == "EC200") {
        setAmenities(resp.data.data.amenities)
      }
      dispatch(updateLoader({ loader: false }));
    } catch (error) {
      dispatch(updateLoader({ loader: false }));
      if (error.response && error.response.status === 404) {
        console.log('404 ', error.response.data.message)
      } else {
        console.log('error ', error.message);
      }
      setAmenities([])
    }
  }

  const checkAvailability = async (e, filers) => {
    try {
      dispatch(updateLoader({ loader: true }));
      const params = {
        property_id: e,
        check_in: filers.check_in,
        check_out: filers.check_out,
        adults: filers.adults,
        children: filers.children,
        infants: filers.infants,
        rooms: filers.rooms,
      }
      console.log('params ', filers)
      const resp = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/check-availability/`, params)
      if (resp.data.code == "EC200") {
        console.log(resp.data.data);
        setResp(resp.data.data)
        setErrorMsg(resp.data.data.message)
      }
      dispatch(updateLoader({ loader: false }));
    } catch (error) {
      dispatch(updateLoader({ loader: false }));
      if (error.response && error.response.status === 404) {
        console.log(error.response)
      } else {
        console.log(error.message);
      }
    }
  }
  useEffect(() => {
    checkAvailability(id, filers)
  }, [filers, id])

  useEffect(() => {
    getRating(id)
    getProperty(id);
    getRecommended(id)
    getAmenities(id)
  }, [id])

  const [ratingModel, setRatingModel] = useState(false)
  const [rate, setRate] = useState(5)
  const [reviews, setReviews] = useState("")
  const handleLoginClose = () => {
    setRatingModel(!ratingModel)
  };

  const addReview = async (id, rate, review) => {
    try {
      dispatch(updateLoader({ loader: true }));
      const params = {
        property_id: id,
        rating: rate,
        review: review,
      }
      const resp = await Axios.post('/ratings/', params)
      if (resp.data.code == "EC200") {
        console.log(resp.data.data);
        setRatingModel(false)
      }
      dispatch(updateLoader({ loader: false }));
    } catch (error) {
      dispatch(updateLoader({ loader: false }));
      if (error.response && error.response.status === 404) {
        console.log(error.response)
      } else {
        console.log(error.message);
      }
    }
  }
  const handleUpdate = (e) => {
    if (e < 1) {
      setError(``);
    } else {
      setError('');
      dispatch(updateFilters({ rooms: e }));
    }
  };
  const handleUpdateGuest = (e) => {
    if (e < 1) {
      setError(``);
    } else {
      setError('');
      dispatch(updateFilters({ adults: e }));
    }
  };
  useEffect(() => {
    const storedDateRange = JSON.parse(localStorage.getItem('dateRange'));
    if (storedDateRange) {
      setSelectedDateRange([new Date(storedDateRange[0]), new Date(storedDateRange[1])]);
      dispatch(updateFilters({ check_in: getFormatDate(new Date(storedDateRange[0])), check_out: getFormatDate(new Date(storedDateRange[1])) }));
    } else {
      setSelectedDateRange([new Date(filers.check_in), new Date(filers.check_out)]);
    }
  }, [dispatch, filers.check_in, filers.check_out]);

  const handleDateChange = (range) => {
    if (range && range.length === 2) {
      const [startDate, endDate] = range;

      // Normalize dates to remove time and compare only dates
      const start = new Date(startDate.toDateString());
      const end = new Date(endDate.toDateString());

      if (start.getTime() === end.getTime()) {
        alert("Check-in and check-out dates cannot be the same. Please select different dates.");
        return;
      }

      // Proceed if dates are different
      setSelectedDateRange(range);
      dispatch(updateFilters({ check_in: getFormatDate(range[0]), check_out: getFormatDate(range[1]) }));
      localStorage.setItem('dateRange', JSON.stringify(range));
    }
  };



  function capitalizeFirstLetter(word) {
    if (word !== null) { return word.charAt(0).toUpperCase(); }
    else {
      return null;
    }
  }
  const images = property?.images.slice(0, 5) || [];



  return (
    <>


      <Modal show={ratingModel} onHide={handleLoginClose} className='review_model_main_wrapper d-none'>
        <div className='review_model_header'>
          <div className='reviewtitle-model'>Write Review</div>
        </div>


        <div class="review-form-wrapper">
          <form>
            <div class="rating-popup">
              <div className='rating_review-list_title'>How was the experience?</div>

              <div class="stars">
                <input type="radio" name="rating" id="star1" value="5" />
                <label for="star1" title="Very Good">★
                  <div className='review_rating_name_title'>Very Good</div>
                </label>

                <input type="radio" name="rating" id="star2" value="4" />
                <label for="star2" title="Good">★
                  <div className='review_rating_name_title'>Good</div>
                </label>

                <input type="radio" name="rating" id="star3" value="3" />
                <label for="star3" title="OK-Ok">★
                  <div className='review_rating_name_title'>Ok-Ok</div>
                </label>
                <input type="radio" name="rating" id="star4" value="2" />
                <label for="star4" title="Bad">★
                  <div className='review_rating_name_title'>Bad</div>
                </label>
                <input type="radio" name="rating" id="star5" value="1" />
                <label for="star5" title="Very Bad">★
                  <div className='review_rating_name_title'>Very Bad</div>
                </label>
              </div>
            </div>
            <div class="photo-upload">
              <div className='rating_review-list_title'>Share Photos now!
                <div className='rating_review-list_sub_title'>Capture and add your Property experience with photos</div>
              </div>
              <div className='upload_images_list'>
                <ul>
                  <li>
                    <img src="/images/d2.png" className='img-responsive uploade-img-review' />
                    <div className='upload_images_list_clode_btn'><i className='fa fa-close'></i></div>
                  </li>
                  <li>
                    <img src="/images/d1.png" className='img-responsive uploade-img-review' />
                    <div className='upload_images_list_clode_btn'><i className='fa fa-close'></i></div>
                  </li>
                  <li>
                    <img src="/images/d3.png" className='img-responsive uploade-img-review' />
                    <div className='upload_images_list_clode_btn'><i className='fa fa-close'></i></div>
                  </li>
                  <li>
                    <img src="/images/d1.png" className='img-responsive uploade-img-review' />
                    <div className='upload_images_list_clode_btn'><i className='fa fa-close'></i></div>
                  </li>

                  <li>
                    <div class="upload-area-small">
                      <input type="file" id="photo-upload" accept="image/*" />
                      <label for="photo-upload"><img src="/icon/plusupoad.png" className='img-fluid' /></label>
                    </div>
                  </li>

                </ul>
              </div>
              <div class="upload-area">
                <input type="file" id="photo-upload" accept="image/*" />
                <label for="photo-upload"><img src="/icon/camera.png" className='img-fluid' /></label>
              </div>
            </div>
            <div class="review-text">
              <div className='rating_review-list_title'>Write Review</div>
              <textarea id="review" rows="4"></textarea>
            </div>
            <button type="submit" className='btn review-popup-btn'>Submit</button>
          </form>
        </div>


        <Modal.Body>

          {property && (
            <>



              <div className="col-md-12">
                <div className="property_rental_deatils_main">
                  <div className="details_picture_banner">
                    <img src={property?.images[0]?.image} className="img-fluid" />
                  </div>
                </div>
                <div className="details_pages_rightside_flex">
                  <div className="details_pages_rightside_inner">
                    <div className="picture_title_right">
                      <div className="details_picture_tittle">
                        <div className='review_model_hohelname_title'><h4>{property.name}</h4></div>
                      </div>
                      <div className="details_picture_sub_tittle">
                        <div className='review_model_city_country_title'><h5>{property.country}, {property.city}</h5></div>
                      </div>

                    </div>
                  </div>

                  <div className="h-100 m-1 justify-content-left align-items-center">
                    <Rating className="rating_star" name="half-rating" value={rate} precision={1}
                      onChange={(event, newValue) => {
                        setRate(newValue);
                      }} />
                    <textarea placeholder='Please write your review' rows="3" cols="3" value={reviews} onChange={(e) => setReviews(e.target.value)} className='form-control ratingtext'> </textarea>
                    <div className='btn  btn-sm rewiewBtn' onClick={() => addReview(property.id, rate, reviews)}>
                      send Reviews
                    </div>
                  </div>


                </div>


              </div>
            </>
          )}
        </Modal.Body>
      </Modal >

      {property && (
        <div className="property_detaols_main_wrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="property_rental_deatils_main">
                  <div className="details_picture_banner">
                    <img src={property?.images[0]?.image} className="img-fluid" />
                  </div>
                </div>


                <div className='mobile_slider_property'>
                  <Carousel data-bs-theme="dark">

                    {images.map((image, index) => (
                      <Carousel.Item key={index} className='mobile_multi_sliderimages'>
                        <img src={image?.image} className="img-fluid" alt={`Slide ${index}`} />
                      </Carousel.Item>
                    ))}

                  </Carousel>
                </div>
 
                <div className="details_pages_rightside_flex">
                  <div className="details_pages_rightside_inner">
                    <div className="picture_title_right">
                      <div className="details_picture_tittle">
                        <h4>
                          {property.name}
                        </h4>
                      </div>
                      <div className="details_picture_sub_tittle">
                        <h5>
                          {property.country}, {property.city}
                        </h5>
                      </div>

                    </div>
                    {property.ratings !== 0 && (<div className="picture_rating_left">
                      <div className="picture_rating_inner_wrapper">
                        <div className="card_box_riben_dtais_page">
                          {" "}
                          {property.rating} <i className="fa fa-star" />
                        </div>
                        <div className="r1lutz1s">{property.ratings} Ratings</div>
                      </div>
                    </div>)}
                  </div>
                </div>

                <div className='details_pages_dis'>
                  <p>
                    {property.description}
                  </p> 
                  <ul class="product_deatils_list">
                    <li>Check In Time : {checkin}</li>
                    <li>Check Out Time : {checkout}</li>
                    <li>Room : {property.details.room}</li>
                    <li>Adults : {property.details.adults}</li>
                    <li>Children : {property.details.children}</li>
                    <li>Infants :{ property.details.infants}</li>
                    <li>Bedroom : {property.details.bedroom}</li>
                  </ul>
 
                </div>
                {
                  amenities.length > 0 && (
                    <div className="features_main_wrapper">
                      <div className="features_main_title">Amenities</div>
                      <div className="features_inner_wrapper">
                        <div className="features_lists_wrapper">
                          {
                            amenities.map((item, value) => (
                              <div className="features_lists">
                                <div className="iikjzje">
                                  <div className="i4wvyiy"><img src={item.icon} className="{item.name}" /></div>
                                  <div className="nametext">{item.name}</div>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  )
                }
                <div className="Rating_review_main_wrapper">
                  <div className="features_main_title">Rating &amp; Reviews <span className="reviewbtn" onClick={() => handleLoginClose()}></span></div>
                  <div className="rating_details_wrapper">
                    <div className="row align-items-center">
                      <div className="col-md-3 col-lg-2">
                        <div className='rating_center_box'>
                          <div className="ratingbox">
                            <div className="card_box_riben_dtais_page_rightbox">
                              <div className='review_rating_box'>
                                {property.rating} <i className="fa fa-star" />
                                <div className="reveiw_number">{property.ratings} Rating</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-9 col-lg-10">
                        <div className='row'>

                          {Object.entries(property.ratingstar).map(([rating, percentage]) => (
                            <div className={`${rating == 5 ? 'col-sm-12' : 'col-sm-6'}`}>
                              <div className=" task-progress">
                                <p><span>{rating} <i className="fa fa-star" /></span><span>{percentage}</span></p>
                                <progress
                                  className="progress progress1"
                                  max={100}
                                  value={parseInt(percentage)}
                                />
                              </div>
                            </div>
                          ))}

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="review_main_wrapper">

                    {rating.map((item, ind) => (
                      <div className="review_inner_wrapper">
                        <div className="review_user_img">
                          <Avatar src={item.image} sx={{ bgcolor: deepPurple[500], width: 56, height: 56 }}>{capitalizeFirstLetter(item.name)} </Avatar>
                        </div>
                        <div className="review_user_dis">
                          <div className="review_user_name">
                            <h4 className='' style={{ marginRight: 20 }}>{item.name}</h4>
                            <div >
                              <Rating name="half-rating-read" defaultValue={item.rating} precision={0.5} size="small" readOnly />
                            </div>
                          </div>

                          <div className="review_log_dis">
                            <h5>
                              {item.review}
                            </h5>
                          </div>
                        </div>
                      </div>
                    ))}

                    {rating.length > 1 && (
                      <div className="allreview_btn">
                        <div className="booknow_btn">
                          <Link to={'/product-rating/' + id} style={{ textDecorationLine: 'none' }} >Show All</Link>
                        </div>
                      </div>
                    )}

                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className='product_list_wrapper'>
                  <ul className='places gap-2 '>
                    {property.images.slice(1, 5).map((item, ind) => (
                      <li key={ind} className='img-short'>
                        <img src={item.image} alt={`Image ${ind}`} />
                      </li>
                    ))}
                    {property.images.length > 1 && (
                      <div className="photo_grid-more">
                        <button type="button" onClick={() => setOpen(true)}>
                          <span>Show All <span className="hide">Photos</span></span>
                        </button>
                      </div>
                    )}
                  </ul>
                  {open && (
                    <Lightbox
                      open={open}
                      close={() => setOpen(false)}
                      slides={property.images.map(item => ({ src: item.image }))}
                    />
                  )}
                </div>

                <div className="details_pages_sidebar">
                  {resp && (
                    <div className="details_pages_price gap-2">
                      <div className="price">
                        {common.currency}
                        {parseInt(resp.data.product_discounted_price)}
                      </div>
                      {resp && resp.data.discount && resp.data.discount !== '0%' ? (
                        <div className="htro_price">
                          {common.currency}
                          {property.price}
                        </div>
                      ) : null}
                      {resp && resp.data.discount && resp.data.discount !== '0%' ? (
                        <div className="discount_price">{resp && resp.data.discount && resp.data.discount != '0%' ? resp.data.discount + 'OFF' : ''} </div>) : null}
                    </div>)}
                  <div className="order-container">


                    <div className="order-data">

                      <div className="date-picker">
                        <div className="guest-input1 check-in gest_search1 gest_search details_pagecheck w-100">
                          <div className='gest_search_adult1 '>
                            <div className='details_flex'>
                              <div className='Product_detils_icons'><img src="/icon/checkin.png" /></div>
                              <div className="input_box signle-product-space">
                                <div className="searchmain_title">Check In /Check Out</div>
                                <DateRangePicker
                                  size="xs"
                                  placeholder={'Please select check in/out date'}
                                  format="dd MMM yy"
                                  character={" - "}
                                  showHeader={false}
                                  showOneCalendar={showOneCalendar}
                                  onOk={handleDateChange}
                                  shouldDisableDate={beforeToday()}
                                  cleanable={false}
                                  value={selectedDateRange}
                                />

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="guest-input1 gest_search">
                        <div className='details_flex '>
                          <div className="Product_detils_icons"><img src="/icon/geust.png" className='img-fluid' /></div>
                          <div className="guests gest_search1">
                            <Dropdown className='details_click_open'>
                              <Dropdown.Toggle variant="none" style={{ border: "none" }} className='adult_input_box'>
                                <div className='gest_search_adult'>
                                  <div className="input_box signle-product-space">
                                    <div className="searchmain_title">Adults</div>
                                    <div className='serach_input'>{`${filers.adults} Guests, ${filers.children} Children, ${filers.infants} Infants, ${filers.rooms} Rooms `} </div>
                                  </div>
                                </div>
                              </Dropdown.Toggle>

                              <Dropdown.Menu className='adult_dropdown_home1 details_page_adutldrop'>
                                <div className='adultdropdown_titlename'>Who’s coming?</div>
                                <Countinc title={"Adults"} update={handleUpdateGuest} value={filers.adults} />
                                <Countinc title={"Children"} update={(e) => dispatch(updateFilters({ children: e }))} value={filers.children} />
                                <Countinc title={"Infants"} update={(e) => dispatch(updateFilters({ infants: e }))} value={filers.infants} />
                                {/* <Countinc title={"Rooms"} update={handleUpdate} value={filers.rooms} /> */}
                                {error && <p style={{ color: 'red' }}>{error}</p>}
                                {/* <Countinc title={"Bedroom"} update={(e)=>dispatch(updateFilters({ bedroom: e }))} value={filers.bedroom}/>  */}
                              </Dropdown.Menu>

                            </Dropdown>
                          </div>
                        </div>
                      </div>


                    </div>
                    {resp && resp.status == 1 ? (
                      <>
                        <div className="details_page_price_wrapper">
                          <div className="details_page_inner_wrapper">
                            <div className="details_price_title_resverbox">
                              {common.currency}{property.price} x {resp.data.differenceDays} Nights
                            </div>
                            <div className="details_price_resverbox">
                              {common.currency}{resp.data.totalPrice}
                            </div>
                          </div>
                          <div className={`details_page_inner_wrapper ${resp?.data.discountAmount === 0 ? 'd-none' : ''}`}>
                            <div className="details_price_title_resverbox">Instant Discount - {resp?.data.discount}</div>
                            <div className="details_price_resverbox">
                              -{common.currency} {resp?.data.discountAmount}
                            </div>
                          </div>
                          <div className="details_page_inner_wrapper">
                            <div className="details_price_title_resverbox">Tax</div>
                            <div className="details_price_resverbox">
                              {common.currency}{resp.data.tax}
                            </div>
                          </div>
                          <div className="details_page_inner_wrapper">
                            <div className="details_price_title_resverbox">Service fee</div>
                            <div className="details_price_resverbox">
                              {common.currency}{resp.data.servicefee}
                            </div>
                          </div>


                          <div className="details_page_inner_wrapper">
                            <div className="details_price_title_resverbox">
                              Payable Price
                              <p className="subtax_price">Including Tax Fees</p>
                            </div>
                            <div className="details_price_resverbox">
                              {common.currency}{parseInt(resp.data.payablePrice)}
                            </div>
                          </div>
                        </div>
                        <div className="content_button_details">
                          <div className="action-btn" onClick={() => reserveNow(property)}>
                            <Link className="resverBtn">Reserve Now</Link>
                          </div>
                        </div>
                      </>
                    ) : (
                      authenticatedid ? (
                        <div className="property_notavible_alert_wrapper">
                          <div className="property_notavible_alert">
                            {errorMsg ? errorMsg : 'This property is not available on this date. Please select another date.'}
                          </div>
                        </div>
                      ) : (
                        <div className="content_button_details mt-4">
                          <div className="action-btn" onClick={() => dispatch(updateCommons({ loginshow: true }))}>
                            <Link className="resverBtn">Reserve Now</Link>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>


              </div>
            </div>
          </div>
          <div className="container">
            <div className="recent_product_title">
              <h4>Recommended properties nearby</h4>
            </div>
            <div className="row">
              {recommended.map((item, ind) => (
                <ProductCard key={ind} data={item} />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Product